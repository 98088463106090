<template>
  <customer-in-charge-list-tab>
    <template v-slot:content>
      <div>
        <div class="d-flex justify-space-between mb-2">
          <!-------- LEFT ITEMS ---------->
          <div class="d-flex justify-start pr-4">
            <!--  -->
            <span class="text-12px mt-1 color-title mr-2">購入評価</span>
            <v-select
              :items="itemsData.enumPurchaseRateList"
              multiple
              item-text="name"
              item-value="id"
              v-model="dataPurchaseRateSelected"
              dense
              outlined
              hide-details
              style="max-width: 270px"
              class="mr-4 v-select-custom"
            ></v-select>
            <span class="text-12px mt-1 color-title">{{
              $t('myPage.CustomerInCharge.salesRepresentative')
            }}</span>
            <v-select
              :items="itemsData.picList"
              item-text="name"
              item-value="id"
              v-model="dataPicPurchaseEvaluationSelected"
              dense
              outlined
              hide-details
              style="width: 72px"
              class="ml-2 mr-4 v-select-custom"
            ></v-select>
            <span class="text-12px mt-1 color-title">最新広告施策</span>
            <my-page-client-list-campaign-list v-model="dataLastCampaignApplyResponseSelected" />
          </div>
          <!-------- RIGHT ITEMS ---------->
          <div class="d-flex justify-end mt-2">
            <span class="text-10px mt-1 color-title">担当顧客総計</span>
            <span class="text-14px" style="color: #0b6786">{{
              totalCount
            }}</span>
            <span class="text-10px mt-1" style="color: #0b6786">{{
              $t('commons.item')
            }}</span>
          </div>
        </div>
        <v-card>
          <customer-in-charge-table
            ref="table"
            :total="totalCount"
            :filter="filter"
            :items="searchPurchaseEvaluationList"
            :funReset="loadList"
            purchase
          />
        </v-card>
      </div>
    </template>
  </customer-in-charge-list-tab>
</template>

<script>
import CustomerInChargeListTab from './CustomerInChargeListTab.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import MyPageClientListCampaignList from './MyPageClientListCampaignList.vue';
import CustomerInChargeTable from './CustomerInChargeTable.vue';

export default {
  components: { 
    CustomerInChargeListTab,
    MyPageClientListCampaignList,
    CustomerInChargeTable
  },
  name: 'MypageListTab',
  data() {
    return {
      filter: {
        purchaseRates: this.dataPurchaseRateSelected,
        picIds: 10,
        lastCampaignId: null
      },
      itemsData: {
        enumPurchaseRateList: [],
        picList: [],
        lastCampaignApplyResponseList: []
      },
      totalCount: 0,
      ready: false,
    };
  },
  computed: {
    email() {
      return !this.$store.getters.user ? false : this.$store.getters.user.email;
    },
    ...mapGetters([
      'searchPurchaseEvaluationList',
      'purchaseRate',
      'picPurchaseEvaluation',
      'lastCampaignApplyResponsePurchaseEvaluation',
      'purchaseRateSelected',
      'picPurchaseEvaluationSelected',
      'lastCampaignApplyResponsePurchaseEvaluationSelected'
    ]),
    dataPurchaseRateSelected: {
      get() {
        return this.purchaseRateSelected;
      },
      set(value) {
        this.setPurchaseRateSelected(value);
      },
    },
    dataPicPurchaseEvaluationSelected: {
      get() {
        return this.picPurchaseEvaluationSelected;
      },
      set(value) {
        this.setPicPurchaseEvaluationSelected(value);
      },
    },
    dataLastCampaignApplyResponseSelected: {
      get() {
        return this.lastCampaignApplyResponsePurchaseEvaluationSelected;
      },
      set(value) {
        this.setLastCampaignApplyResponsePurchaseEvaluationSelected(value);
      },
    },
  },
  watch: {
    dataPurchaseRateSelected () {
      this.filter.purchaseRates = this.dataPurchaseRateSelected
      this.$refs.table.reset()
    },
    dataPicPurchaseEvaluationSelected () {
      this.filter.picIds = this.picIds
      this.$refs.table.reset()
    },
    dataLastCampaignApplyResponseSelected () {
      this.filter.lastCampaignId = this.lastCampaignId
      this.$refs.table.reset()
    },
  },
  async mounted() {
    this.ready = true;
    this.$refs.table.reset()
    await this.actionStaffList().then(pic => {
      this.itemsData.picList.push({ id: null, name: '指定なし' });
      pic.forEach(element => {
        this.itemsData.picList.push({id: element.id, name: element.name })
      })
    })
    await this.actionEnumPurchaseRateList().then((enumPurchaseRateList) => {
      this.itemsData.enumPurchaseRateList = enumPurchaseRateList;
    });
    await this.setPurchaseRateSelected([21, 31, 41]);
  },
  methods: {
    async loadList(variables) {
      if(variables){
        if (!this.ready) return;
        const {total} = await this.getSearchPurchaseEvaluationList({
          take: variables.pagination.take,
          skip: variables.pagination.skip,
          orderBy: variables.orderBy
        });
        this.totalCount = total;
      }
    },
    ...mapActions([
      'getSearchPurchaseEvaluationList',
      'actionEnumPurchaseRateList',
      'getEnumPurchaseEvaluation',
      'actionStaffList'
    ]),
    ...mapMutations([
      'setPurchaseRateSelected',
      'setPicPurchaseEvaluationSelected',
      'setLastCampaignApplyResponsePurchaseEvaluationSelected',
      'setPicPurchaseEvaluation',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.tab-custom {
  &__tab {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__total {
    width: 40%;
    text-align: right;
    align-self: flex-end;
    color: var(--text_active_000000);
  }
}
.info-detail-title {
  color: var(--text_active_000000);
}
@media (max-width: 600px) {
  .v-card__title {
    flex-direction: column;
    align-items: flex-end;
  }
  .intro-info div {
    display: block;
    label {
      width: auto;
      text-align: left;
    }
  }
}
.color-title {
  background: unset !important;
  color: #000000;
  word-break: keep-all;
}
.text-14px {
  font-size: 14px;
}
.table-custom {
  ::v-deep {
    .v-data-table__wrapper {
      table > tbody > tr > td {
        &:nth-child(7) {
          max-width: 330px;
        }
        &:nth-child(8) {
          max-width: 330px;
        }
        &:nth-child(9) {
          max-width: 330px;
        }
      }
    }
  }
}
</style>
